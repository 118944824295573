$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: $white;
  overflow: hidden;
  outline: none;

  &.default {
    .price {
      display: none;

      @media screen and (min-width: $breakpoint_l) {
        display: block;
      }
    }
  }

  &.ad {
    background: $black;

    & > div:first-child {
      border: none;
    }
  }

  &.theme {
    &-white {
      background: $white;

      .storeButton {
        color: $dove-gray;
      }

      .viewMoreLabel {
        color: $black;
      }
    }

    &-black {
      background-color: $black;

      .storeButton {
        color: $white;

        &:hover {
          color: $white;
        }
      }

      .viewMoreLabel {
        color: $white;
      }
    }
  }
}

.imageWrapper {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  background-color: $white;
  border: $border-light-grey;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  aspect-ratio: 1;
}

.image {
  margin: 0 auto;
  height: 100%;
  display: block;
}

.infoWrapper {
  margin: 6px 0 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title,
.price,
.cta {
  color: $white;
  mix-blend-mode: difference;
}

.title {
  @include text-sm-medium;
  @include line_clamp(2);
  // this is important for virtual list scroll
  height: 44px;
  line-height: 20px;

  &.large {
    @media screen and (min-width: $breakpoint_l) {
      @include text-lg-medium;
    }
  }
}

.price {
  color: $gainsboro;

  @include text-sm-regular;

  &.large {
    @media screen and (min-width: $breakpoint_l) {
      @include text-base-regular;
    }
  }
}

.infoFooter {
  padding: 2px 0 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.infoFooterWrapper {
  display: flex;
  justify-content: flex-start;
}

.storeButton {
  margin: 0 0 4px 0;
  padding: 0;
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  @include line_clamp(1);
  // this is important for virtual list scroll
  height: 17px;

  @include inline_overflow_ellipsis;
  @include text-sm-regular-underline;

  @media screen and (min-width: $breakpoint_l) {
    padding-right: 7px;

    @include text-sm-regular-underline;
  }
}

.cta {
  @include text-sm-regular-underline;
}

.viewMoreLabel {
  width: 100%;
  border: $border-light-grey;
  text-align: center;
  padding: 8px 0;

  @include text-xs-medium-uppercase;

  @media screen and (min-width: $breakpoint_l) {
    margin-top: 8px;
  }
}
