$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  position: relative;

  &.theme {
    &-white {
      .navButton {
        background-color: $black;

        &:hover {
          .arrowIcon {
            color: $hover-color;
          }
        }
      }

      .arrowIcon {
        color: $white;
      }
    }

    &-black {
      .navButton {
        background-color: $white;

        &:hover {
          .arrowIcon {
            color: $dove-gray;
          }
        }
      }

      .arrowIcon {
        color: $black;
      }
    }
  }
}

.nav {
  position: absolute;
  right: 0;
  top: -100px;
  display: none;

  @media screen and (min-width: $breakpoint_l) {
    display: flex;
  }
}

.navHide {
  display: none;
}

.navButton {
  width: 58px;
  height: 58px;
  border: none;
  outline: none;
  cursor: pointer;

  @include flexCenter;

  &:first-child {
    margin-right: 8px;
  }
}

.arrowIcon {
  width: 8px;
  height: 14px;

  &.left {
    position: relative;
    left: -2px;
  }

  &.right {
    position: relative;
    right: -2px;
  }
}


.slider {
  margin-right: -$mobile_gutter;
  width: 100%;

  @media screen and (min-width: $breakpoint_l) {
    margin-right: -$desktop_gutter;
  }

  [class*="slick-track"] {
    display: flex;
  }

  [class*="slick-slide"] {
    height: inherit;

    & > div {
      height: 100%;
      margin: 0 8px 0 0;

      @media screen and (min-width: $breakpoint_l) {
        margin: 0 16px 0 0;
      }
    }
  }
}

.root::after {
  @media screen and (min-width: $breakpoint_xxl) {
    height: 100%;
    width: 72px;
    position: absolute;
    top: 0;
    right: -100px;
    content: "";
    display: block;
    background: linear-gradient(
      270deg,
      #ffffff 27.91%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.theme-black.root::after {
  background: linear-gradient(270deg, #000000 27.91%, rgba(0, 0, 0, 0) 100%);
}
